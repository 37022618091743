<template>
  <div
    class="teaser-register-success d-flex justify-center align-center min-h-screen content-wrapper-small"
  >
    <div class="teaser-register-success__content">
      <div
        class="teaser-register-success__register-name font-weight-bold text-uppercase"
      >
        {{ $t('label.hey') }} {{ teaserInfo.registerName }},
      </div>
      <div class="teaser-register-success__register-message font-weight-light">
        <div v-html="successMessage"></div>
      </div>

      <!-- Sharing Row Start -->
      <v-row
        no-gutters
        align="center"
        justify="center"
        justify-md="space-between"
        class="teaser-register-success__sharing-row"
      >
        <v-col
          cols="12"
          md="6"
          class="d-flex align-center justify-center mb-4 mb-sm-8 mb-md-0"
        >
          <div class="teaser-register-success__sharing-label">
            {{ $t('label.shareToYourFriendNow') }}
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex align-center justify-center justify-md-start"
        >
          <AppButton
            class="teaser-register-success__sharing-button rounded-pill"
            @click="shareLinkAction"
          >
            <span class="teaser-register-success__sharing-label">{{
              $t('label.shareNow')
            }}</span>
          </AppButton>
          <input
            class="d-none"
            type="text"
            :value="platformOrigin"
            id="platformOrigin"
          />
        </v-col>
      </v-row>
      <!-- Sharing Row End -->
    </div>
    <video autoplay muted loop playsinline id="backgroundVideo">
      <source
        src="/assets/video/tadaa-success-registration-background.mp4"
        type="video/mp4"
      />
    </video>
  </div>
</template>

<script>
  import { uiHelper, i18nHelper } from '@/utils';
  import { SESSION, ROUTE_NAME } from '@/constants';
  import { ROLE_TYPE } from '@/constants/enums';

  export default {
    name: 'TeaserRegisterSuccess',
    beforeRouteLeave(to, from, next) {
      uiHelper.removeSessionStorage(SESSION.TEASER);

      if (uiHelper.getSessionStorage(SESSION.TEASER)) {
        uiHelper.removeSessionStorage(SESSION.TEASER);
      } else {
        next();
      }
    },
    computed: {
      teaserInfo() {
        let info = uiHelper.getSessionStorage(SESSION.TEASER);
        return info ? JSON.parse(info) : '';
      },
      successMessage() {
        if (this.teaserInfo.role == ROLE_TYPE.TALENT) {
          return i18nHelper.getMessage(
            'message.congratulationsOnJoiningTheCommunityMsg1'
          );
        } else {
          return i18nHelper.getMessage(
            'message.congratulationsOnJoiningTheCommunityMsg2'
          );
        }
      },
      platformOrigin() {
        return location.origin;
      }
    },
    mounted() {
      this.checkOutsider();
    },
    methods: {
      checkOutsider() {
        /* 
        If a user has direct access to teaser register success view, 
        redirect the user to the home view. 
        */

        if (!this.teaserInfo) {
          this.$router.push({ name: ROUTE_NAME.HOME });
        }
      },
      shareLinkAction() {
        let OS = uiHelper.getOperatingSystem();
        let browser = uiHelper.getBrowser();
        let shareData = {
          title: i18nHelper.getMessage('label.brandName'),
          text: i18nHelper.getMessage('message.homeFirstMessage'),
          url: this.platformOrigin
        };
        let shareDateMessage = `${i18nHelper.getMessage(
          'message.homeFirstMessage'
        )} ${this.platformOrigin}`;
        let vm = this;

        if (browser == 'HuaweiBrowser') {
          copyToClipboard(shareDateMessage);
        } else {
          if (['IOS', 'Android', 'Huawei'].includes(OS)) {
            navigator.share(shareData);
          } else {
            copyToClipboard(shareDateMessage);
          }
        }

        function copyToClipboard(text) {
          vm.$copyText(text).then(
            () => {
              alert(i18nHelper.getMessage('label.copied'));
            },
            () => {
              alert(i18nHelper.getMessage('label.failToCopy'));
            }
          );
        }
      }
    }
  };
</script>

<style lang="scss">
  .teaser-register-success {
    .teaser-register-success__content {
      z-index: 1;

      .teaser-register-success__register-name {
        font-size: 5.625rem;
      }
      .teaser-register-success__register-message {
        font-size: 2.5rem;
        margin-bottom: 60px;
      }

      .teaser-register-success__sharing-row {
        padding: 48px;
        border: 1px solid var(--v-divider-base);
        .teaser-register-success__sharing-label {
          font-size: 2rem;
        }
        .teaser-register-success__sharing-button {
          min-height: 70px !important;
          min-width: 250px !important;
        }
      }
    }

    #backgroundVideo {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 1903px) {
    .teaser-register-success {
      .teaser-register-success__content {
        .teaser-register-success__register-name {
          font-size: 5rem;
        }
        .teaser-register-success__register-message {
          font-size: 1.75rem;
          margin-bottom: 58px;
        }
        .teaser-register-success__sharing-row {
          padding: 46px;
          .teaser-register-success__sharing-label {
            font-size: 1.75rem;
          }
          .teaser-register-success__sharing-button {
            min-height: 65px !important;
            min-width: 225px !important;
          }
        }
      }
    }
  }
  @media (max-width: 1599px) {
    .teaser-register-success {
      .teaser-register-success__content {
        .teaser-register-success__register-name {
          font-size: 4.75rem;
        }
        .teaser-register-success__register-message {
          font-size: 1.65rem;
          margin-bottom: 56px;
        }
        .teaser-register-success__sharing-row {
          padding: 44px;
          .teaser-register-success__sharing-label {
            font-size: 1.725rem;
          }
          .teaser-register-success__sharing-button {
            min-height: 60px !important;
            min-width: 200px !important;
          }
        }
      }
    }
  }
  @media (max-width: 1535px) {
    .teaser-register-success {
      .teaser-register-success__content {
        .teaser-register-success__register-name {
          font-size: 4.5rem;
        }
        .teaser-register-success__register-message {
          font-size: 1.55rem;
          margin-bottom: 54px;
        }
        .teaser-register-success__sharing-row {
          padding: 42px;
          .teaser-register-success__sharing-label {
            font-size: 1.7rem;
          }
          .teaser-register-success__sharing-button {
            min-height: 55px !important;
            min-width: 175px !important;
          }
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .teaser-register-success {
      .teaser-register-success__content {
        .teaser-register-success__register-name {
          font-size: 4.25rem;
        }
        .teaser-register-success__register-message {
          font-size: 1.45rem;
          margin-bottom: 52px;
        }
        .teaser-register-success__sharing-row {
          padding: 40px;
          .teaser-register-success__sharing-label {
            font-size: 1.55rem;
          }
          .teaser-register-success__sharing-button {
            min-height: 50px !important;
            min-width: 150px !important;
          }
        }
      }
    }
  }
  @media (max-width: 1335px) {
    .teaser-register-success {
      .teaser-register-success__content {
        .teaser-register-success__register-name {
          font-size: 4rem;
        }
        .teaser-register-success__register-message {
          font-size: 1.5rem;
          margin-bottom: 50px;
        }
        .teaser-register-success__sharing-row {
          padding: 38px;
          .teaser-register-success__sharing-label {
            font-size: 1.5rem;
          }
          .teaser-register-success__sharing-button {
            min-height: 45px !important;
            min-width: 125px !important;
          }
        }
      }
    }
  }
  @media (max-width: 1263px) {
    .teaser-register-success {
      .teaser-register-success__content {
        .teaser-register-success__register-name {
          font-size: 3.75rem;
        }
        .teaser-register-success__register-message {
          font-size: 1.25rem;
          margin-bottom: 45px;
        }
        .teaser-register-success__sharing-row {
          padding: 36px;
          .teaser-register-success__sharing-label {
            font-size: 1.25rem;
          }
          .teaser-register-success__sharing-button {
            min-height: 40px !important;
            min-width: 100px !important;
          }
        }
      }
    }
  }
  @media (max-width: 959px) {
    .teaser-register-success {
      .teaser-register-success__content {
        .teaser-register-success__register-name {
          font-size: 3rem;
        }
        .teaser-register-success__register-message {
          font-size: 1rem;
          margin-bottom: 40px;
        }
        .teaser-register-success__sharing-row {
          padding: 25px;
          .teaser-register-success__sharing-label {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  @media (max-width: 599px) {
    .teaser-register-success {
      .teaser-register-success__content {
        .teaser-register-success__sharing-row {
          padding: 20px;
          .teaser-register-success__sharing-label {
            font-size: 1rem;
          }
        }
      }
    }
  }
</style>
